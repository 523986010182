/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomTokenObtainPairRequest } from '../models/CustomTokenObtainPairRequest';
import type { TokenBlacklistRequest } from '../models/TokenBlacklistRequest';
import type { TokenPairCreateResponse } from '../models/TokenPairCreateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns TokenPairCreateResponse
     * @throws ApiError
     */
    public tokenCreate({
        requestBody,
    }: {
        requestBody: CustomTokenObtainPairRequest,
    }): CancelablePromise<TokenPairCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Blacklists a refresh token.
     * @returns any No response body
     * @throws ApiError
     */
    public tokenBlacklistCreate({
        requestBody,
    }: {
        requestBody: TokenBlacklistRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/token/blacklist/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}

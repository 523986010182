<template>
  <div :class="{ [size]: true, 'club-logo': clubLogo, 'female-logo': femaleLogo }" class="avatar">
    <img v-if="url" class="avatar-image" :src="url" :alt="name || undefined" />
    <SvgIcon
      v-else-if="calcIcon"
      :name="size === 'xxl' ? `${calcIcon}-gradient` : calcIcon"
      class="icon"
    ></SvgIcon>
    <span v-else class="name">{{ abbrev }}</span>
    <div class="avatar-overlay" />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

import SvgIcon from '@/components/common/SvgIcon.vue'

const props = defineProps({
  size: { type: String as PropType<'sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl'>, default: 'base' },
  name: { type: String as PropType<string | null | undefined> },
  url: { type: String as PropType<string | null | undefined> },
  icon: { type: String },
  clubLogo: { type: Boolean as PropType<boolean | undefined>, default: false },
  femaleLogo: { type: Boolean as PropType<boolean | undefined>, default: false },
})

const calcIcon = computed(() => props.icon || (props.size === 'xxl' && 'player'))

const abbrev = computed(() =>
  (props.name || '')
    .trim()
    .split(' ')
    .slice(0, 2)
    .map((str) => str[0]?.toUpperCase())
    .join('')
)
</script>

<style scoped lang="postcss">
.avatar {
  @apply relative select-none rounded-full overflow-hidden text-white
  flex items-center justify-center bg-white-10 font-extrabold italic flex-shrink-0;

  &.female-logo {
    @apply bg-gray-500;
  }
  &.club-logo {
    @apply bg-white;
  }
  &.sm {
    @apply h-3 w-3 text-base;

    .icon {
      @apply text-white-40 w-3 h-3;
    }
  }

  &.md {
    @apply h-6 w-6 text-base;

    .avatar-overlay {
      @apply shadow-avatar-border-base;
    }

    .icon {
      @apply text-white-40 w-6 h-6;
    }
  }

  &.base {
    @apply h-7 w-7 text-base;

    .avatar-overlay {
      @apply shadow-avatar-border-base;
    }

    .icon {
      @apply text-white-40 w-5 h-5;
    }

    &.female-logo {
      .icon {
        @apply text-white;
      }
    }
  }

  &.lg {
    @apply h-10 w-10 text-lg;

    .avatar-overlay {
      @apply shadow-avatar-border-lg;
    }

    .icon {
      @apply text-white-40 w-7 h-7;
    }
  }

  &.xl {
    @apply h-16 w-16 text-xl;

    .avatar-overlay {
      @apply shadow-avatar-border-xl;
    }

    .icon {
      @apply absolute h-16 w-16 top-4 left-3;
    }
  }

  &.xxl {
    @apply h-24 w-24 text-xl;

    .avatar-overlay {
      @apply shadow-avatar-border-xl;
    }

    .icon {
      @apply absolute h-24 w-24 top-4 left-3;
    }
  }

  .avatar-image {
    @apply w-full h-full object-cover;
  }

  .avatar-overlay {
    @apply absolute inset-0 rounded-full pointer-events-none;
  }
}
</style>

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OGWalletUserModel } from '../models/OGWalletUserModel';
import type { TokenRequest } from '../models/TokenRequest';
import type { URLData } from '../models/URLData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OgWalletService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns OGWalletUserModel
     * @throws ApiError
     */
    public ogWalletRetrieve(): CancelablePromise<OGWalletUserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/og-wallet/',
        });
    }

    /**
     * Uses sent token to authenticate as a user and get user data including wallet address
     * @returns OGWalletUserModel
     * @throws ApiError
     */
    public ogWalletCreateCreate({
        requestBody,
    }: {
        requestBody: TokenRequest,
    }): CancelablePromise<OGWalletUserModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/og-wallet/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns OG Wallet login URL to log in and validate requested scopes
     * @returns URLData
     * @throws ApiError
     */
    public ogWalletGetLoginUrlRetrieve({
        redirectPath,
    }: {
        /**
         * Redirect path to be appended to the base URL
         */
        redirectPath: string,
    }): CancelablePromise<URLData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/og-wallet/get-login-url',
            query: {
                'redirect_path': redirectPath,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public ogWalletRemoveDestroy(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/og-wallet/remove',
        });
    }

}

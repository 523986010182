/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubUserAccountDetailsUpdateModelRequest } from '../models/ClubUserAccountDetailsUpdateModelRequest';
import type { ClubUserCreateUpdate } from '../models/ClubUserCreateUpdate';
import type { ClubUserCreateUpdateRequest } from '../models/ClubUserCreateUpdateRequest';
import type { ClubUserDetail } from '../models/ClubUserDetail';
import type { ClubUserSystemSettingsUpdateModelRequest } from '../models/ClubUserSystemSettingsUpdateModelRequest';
import type { FanVestorAccountDetailsUpdateModelRequest } from '../models/FanVestorAccountDetailsUpdateModelRequest';
import type { FanVestorDetail } from '../models/FanVestorDetail';
import type { FanVestorPreferencesUpdateModelRequest } from '../models/FanVestorPreferencesUpdateModelRequest';
import type { FanVestorSystemSettingsUpdateModelRequest } from '../models/FanVestorSystemSettingsUpdateModelRequest';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { PatchedClubUserAccountDetailsUpdateModelRequest } from '../models/PatchedClubUserAccountDetailsUpdateModelRequest';
import type { PatchedClubUserCreateUpdateRequest } from '../models/PatchedClubUserCreateUpdateRequest';
import type { PatchedClubUserSystemSettingsUpdateModelRequest } from '../models/PatchedClubUserSystemSettingsUpdateModelRequest';
import type { PatchedFanVestorAccountDetailsUpdateModelRequest } from '../models/PatchedFanVestorAccountDetailsUpdateModelRequest';
import type { PatchedFanVestorPreferencesUpdateModelRequest } from '../models/PatchedFanVestorPreferencesUpdateModelRequest';
import type { PatchedFanVestorSystemSettingsUpdateModelRequest } from '../models/PatchedFanVestorSystemSettingsUpdateModelRequest';
import type { PhoneNumber } from '../models/PhoneNumber';
import type { PhoneNumberRequest } from '../models/PhoneNumberRequest';
import type { PhoneNumberVerificationCodeRequest } from '../models/PhoneNumberVerificationCodeRequest';
import type { ReferralCount } from '../models/ReferralCount';
import type { RegisterFanVestor } from '../models/RegisterFanVestor';
import type { RegisterFanVestorRequest } from '../models/RegisterFanVestorRequest';
import type { RequestPasswordResetRequest } from '../models/RequestPasswordResetRequest';
import type { StripeVerificationSessionModel } from '../models/StripeVerificationSessionModel';
import type { TermsOfUseModel } from '../models/TermsOfUseModel';
import type { User } from '../models/User';
import type { VerificationCodeRequest } from '../models/VerificationCodeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersList(): CancelablePromise<Array<ClubUserDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/club-users/',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersRetrieve({
        userPk,
    }: {
        userPk: number,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/club-users/{user_pk}/',
            path: {
                'user_pk': userPk,
            },
        });
    }

    /**
     * @returns ClubUserCreateUpdate
     * @throws ApiError
     */
    public usersClubUsersEditUpdate({
        userPk,
        requestBody,
    }: {
        userPk: number,
        requestBody: ClubUserCreateUpdateRequest,
    }): CancelablePromise<ClubUserCreateUpdate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/club-users/{user_pk}/edit/',
            path: {
                'user_pk': userPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserCreateUpdate
     * @throws ApiError
     */
    public usersClubUsersEditPartialUpdate({
        userPk,
        requestBody,
    }: {
        userPk: number,
        requestBody?: PatchedClubUserCreateUpdateRequest,
    }): CancelablePromise<ClubUserCreateUpdate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/club-users/{user_pk}/edit/',
            path: {
                'user_pk': userPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersAccountDetailsUpdate({
        requestBody,
    }: {
        requestBody: ClubUserAccountDetailsUpdateModelRequest,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/club-users/account-details/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersAccountDetailsPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedClubUserAccountDetailsUpdateModelRequest,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/club-users/account-details/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersRegisterCreate({
        requestBody,
    }: {
        requestBody: ClubUserCreateUpdateRequest,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/club-users/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersSystemSettingsUpdate({
        requestBody,
    }: {
        requestBody: ClubUserSystemSettingsUpdateModelRequest,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/club-users/system-settings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClubUserDetail
     * @throws ApiError
     */
    public usersClubUsersSystemSettingsPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedClubUserSystemSettingsUpdateModelRequest,
    }): CancelablePromise<ClubUserDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/club-users/system-settings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public usersDisable2FaViaSmsCreate(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/disable-2fa-via-sms/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public usersEmailVerificationCreate({
        requestBody,
    }: {
        requestBody: VerificationCodeRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/email-verification/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public usersEnable2FaViaSmsCreate({
        requestBody,
    }: {
        requestBody: PhoneNumberVerificationCodeRequest,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/enable-2fa-via-sms/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsAccountDetailsUpdate({
        requestBody,
    }: {
        requestBody: FanVestorAccountDetailsUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/fanvestors/account-details',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsAccountDetailsPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedFanVestorAccountDetailsUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/fanvestors/account-details',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsPreferencesUpdate({
        requestBody,
    }: {
        requestBody: FanVestorPreferencesUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/fanvestors/preferences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsPreferencesPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedFanVestorPreferencesUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/fanvestors/preferences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RegisterFanVestor
     * @throws ApiError
     */
    public usersFanvestorsRegisterCreate({
        requestBody,
    }: {
        requestBody: RegisterFanVestorRequest,
    }): CancelablePromise<RegisterFanVestor> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/fanvestors/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsSystemSettingsUpdate({
        requestBody,
    }: {
        requestBody: FanVestorSystemSettingsUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/fanvestors/system-settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FanVestorDetail
     * @throws ApiError
     */
    public usersFanvestorsSystemSettingsPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedFanVestorSystemSettingsUpdateModelRequest,
    }): CancelablePromise<FanVestorDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/users/fanvestors/system-settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns StripeVerificationSessionModel
     * @throws ApiError
     */
    public usersIdentityStripeCreateVerificationSessionCreate(): CancelablePromise<StripeVerificationSessionModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/identity/stripe/create-verification-session/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public usersIdentityStripeVerificationSessionHandlerCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/identity/stripe/verification-session-handler/',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public usersMeRetrieve(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public usersPasswordChangeCreate({
        requestBody,
    }: {
        requestBody: PasswordChangeRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/password-change/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public usersPasswordResetCreate({
        requestBody,
    }: {
        requestBody: PasswordResetRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/password-reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReferralCount
     * @throws ApiError
     */
    public usersReferralClaimBonusCreate(): CancelablePromise<ReferralCount> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/referral/claim-bonus/',
        });
    }

    /**
     * @returns ReferralCount
     * @throws ApiError
     */
    public usersReferralCountRetrieve(): CancelablePromise<ReferralCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/referral/count/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public usersRequestPasswordResetCreate({
        requestBody,
    }: {
        requestBody: RequestPasswordResetRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/request-password-reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PhoneNumber
     * @throws ApiError
     */
    public usersSendPhoneNumberVerificationCodeCreate({
        requestBody,
    }: {
        requestBody: PhoneNumberRequest,
    }): CancelablePromise<PhoneNumber> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/send-phone-number-verification-code/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns latest terms of use
     * @returns TermsOfUseModel
     * @throws ApiError
     */
    public usersTermsOfUseRetrieve(): CancelablePromise<TermsOfUseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/terms-of-use/',
        });
    }

    /**
     * Accepts the latest terms of use
     * @returns any No response body
     * @throws ApiError
     */
    public usersTermsOfUseAcceptCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/terms-of-use/accept/',
        });
    }

}
